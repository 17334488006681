<template>
  <span>
    <div v-if="isFirstEmailSectionVisible" class="d-flex flex-wrap">
      <!-- Name -->
      <b-form-group class="flex-grow-1">
        <label for="email-name" class="font-weight-bold"> {{ $t("backoffice.settings.name") }}</label>
        <b-form-input
          id="email-name"
          v-model="currentEmail.label"
          type="text"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
      <!-- Email -->
      <b-form-group class="ml-sm-1 flex-grow-1">
        <label for="email-name" class="font-weight-bold"> {{ $t("backoffice.settings.email-label") }}</label>
        <b-form-input
          id="email-name"
          v-model="currentEmail.email"
          type="email"
          :state="isValid"
          @input="validator(currentEmail.email)"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
    </div>
    <div class="ml-25 d-flex justify-content-end mb-50">
      <b-button size="sm" variant="primary" @click="addEmailCouple()">
        {{$t('form.actions.add-email')}}
      </b-button>
    </div>
    <label for="email-name" class="font-weight-bold">{{$t('contacts.list')}}</label>
    <div v-if="!emails || emails.length === 0" class="d-flex flex-wrap justify-content-center bg-light px-1 pt-50 mt-50 mb-1 rounded border"><small class="text-muted pt-50 pb-1 font-weight-bold">{{$t('contacts.no-emails')}}</small></div>
    <!-- All emails -->
    <div v-else>
      <div v-for="(email, index) in emails" :key="index" class="d-flex flex-wrap  bg-light px-1 pt-50 mt-50 mb-1 rounded border">
        <!-- Name -->
        <b-form-group class="flex-grow-1">
          <label for="email-name" class="font-weight-bold"> {{ $t("backoffice.settings.name") }}</label>
          <b-form-input
            id="email-name"
            v-model="emails[index].label"
            type="text"
            plaintext
            placeholder="--"
          />
        </b-form-group>
        <!-- Email -->
        <b-form-group class="ml-sm-1 flex-grow-1">
          <label for="email-name" class="font-weight-bold"> {{ $t("backoffice.settings.email-label") }}</label>
          <b-form-input
            id="email-name"
            v-model="emails[index].email"
            type="email"
            plaintext
            @input="validator(emails[index].email)"
            placeholder="--"
          />
        </b-form-group>
        <feather-icon
          icon="Trash2Icon"
          size="16"
          class="ml-50 text-primary pointer mt-2"
          @click="removeItem(index)"
        />
      </div>
  </div>
  </span>
</template>

<script>

import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { validatorEmailValidator } from '@/@core/utils/validations/validators';

export default {
  name: 'Emails',
  mixins: [ToastNotificationsMixin],
  props: {
    mails: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  data() {
    return {
      currentEmail: {
        label: null,
        email: null,
      },
      emails: [],
      isValid: null,
      isFirstEmailSectionVisible: false,
      isRemoving: false,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isSaveEnabled() {
      return this.form.name && this.form.slug && (this.form.accessibilityAux || this.form.accessibility) && (this.form.visibilityAux || this.form.visibility);
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isDisabled() {
      return !this.form.name || !this.form.slug || !this.form.visibility || !this.form.accessibility;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  created() {
    this.isFirstEmailSectionVisible = true;
    this.emails = this.mails ?? [];
  },
  methods: {
    addEmailCouple() {
      console.log('this.emails', this.emails);
      if(!this.emails && this.emails.length <= 0){
        this.emails = [];
      }
      if (this.currentEmail.email && this.currentEmail.email !== null && validatorEmailValidator(this.currentEmail.email)) {
        this.emails.push(this.currentEmail);
        this.currentEmail = {};
        this.isValid = null;
      } else if (this.isRemoving) {
        this.isValid = null;
      } else {
        this.isValid = false;
      }
      this.isFirstEmailSectionVisible = true;
      this.$emit('save-emails', this.emails);
    },
    validator(item) {
      this.isValid = validatorEmailValidator(item);
    },
    removeItem(index) {
      this.emails.splice(index, 1);
      this.isRemoving = true;
      if (this.emails?.length <= 0) {
        this.addEmailCouple();
      }
    },
  },
};
</script>
