<template>
  <span>
    <div v-if="isFirstPhoneSectionVisible" class="d-flex justify-content-between flex-wrap">
      <div class="flex-grow-1 mr-1 mt-50">
        <!-- Name -->
        <b-form-group>
          <label for="phone-name" class="font-weight-bold">
            {{ $t("backoffice.settings.name") }}
          </label>
          <b-form-input
            id="phone-name"
            v-model="currentPhone.label"
            type="text"
            :placeholder="$t('form.type-placeholder')"
          />
        </b-form-group>
      </div>
      <div>
        <!-- Phone -->
        <b-form-group>
          <span class="d-flex flex-wrap">
            <span>
              <label for="phone-prefix" class="font-weight-bold mt-50">
                {{ $t("backoffice.settings.prefix-label") }}
              </label>
              <v-select
                id="phone-prefix"
                v-model="currentPhone.country"
                :placeholder="$t('form.select-placeholder')"
                :options="prefixOptions"
                searchable
                :clearable="false"
                label="name"
                :get-option-label="
                  (a) => a.name[locale] + ' ' + '+' + a.phoneCode
                "
                style="margin-right: 5px; min-width: 200px;"
              />
            </span>
            <span>
              <label for="phone-number" class="font-weight-bold mt-1 mt-md-50">
                {{ $t("backoffice.settings.phone-label") }}
              </label>
              <b-form-input
                id="phone-number"
                v-model="currentPhone.number"
                type="text"
                :state="isValid"
                :placeholder="$t('form.type-placeholder')"
                @input="validator(currentPhone.number)"
              />
            </span>
          </span>
        </b-form-group>
      </div>
    </div>
    <div class="ml-25 d-flex justify-content-end mb-50">
      <b-button size="sm" variant="primary" @click="addPhoneCouple()">
        {{$t('form.actions.add-phone')}}
      </b-button>
    </div>
    <!-- All phones -->
    <label class="font-weight-bold">
      {{ $t('contacts.list-phones') }}
    </label>
    <div v-if="phones.length === 0" class="d-flex flex-wrap justify-content-center bg-light px-1 pt-50 mt-50 mb-1 rounded border">
      <small class="text-muted pt-50 pb-1 font-weight-bold">
        {{ $t('contacts.no-phones') }}
      </small>
    </div>
    <div v-else class="mx-1">
      <b-row
        v-for="(phone, index) in phones"
        :key="index"
        class="d-flex flex-wrap justify-content-between bg-light  pt-50 mt-50 mb-1 rounded border"
      >
        <b-col cols="12" lg="4">
          <!-- Name -->
          <b-form-group class="flex-grow-1">
            <label for="phone-name" class="font-weight-bold">
              {{ $t("backoffice.settings.name") }}
            </label>
            <b-form-input
              id="phone-name"
              v-model="phone.label"
              type="text"
              plaintext
              placeholder="--"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" lg="7">
          <!-- Phone -->
          <b-form-group class="flex-grow-1">
            <span class="d-flex flex-wrap align-items-center">
              <span class="flex-grow-1">
                <label for="phone-name" class="font-weight-bold">
                  {{ $t("backoffice.settings.phone-label") }}
                </label>
                <p v-if="phone.country && phone.country.phoneCode">
                  +{{ phone.country.phoneCode }} {{ getNumber(phone) }}
                </p>
                <p v-else>
                  {{ phone.phone }}
                </p>
              </span>
              <feather-icon
              icon="Trash2Icon"
              size="16"
              class="ml-50 text-primary pointer "
              @click="removeItem(index)"
            />
            </span>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </span>
</template>

<script>
import vSelect from "vue-select";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import { validatorPhoneValidator } from "@/@core/utils/validations/validators";

export default {
  name: "Phones",
  components: {
    vSelect,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    profilePhones: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentPhone: {
        label: null,
        phone: null,
        number: null,
        country: null,
      },
      phones: [],
      isValid: null,
      isValidPrefix: null,
      isFirstPhoneSectionVisible: true,
      prefixOptions: [],
      isRemoving: false,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isSaveEnabled() {
      return (
        this.form.name &&
        this.form.slug &&
        (this.form.accessibilityAux || this.form.accessibility) &&
        (this.form.visibilityAux || this.form.visibility)
      );
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isDisabled() {
      return (
        !this.form.name ||
        !this.form.slug ||
        !this.form.visibility ||
        !this.form.accessibility
      );
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  async created() {
    this.phones = this.profilePhones.map((item) => item);
    await this.getPrefixes();
    this.isFirstPhoneSectionVisible = true;
    this.resetPrefix();
  },
  methods: {
    resetPrefix() {
      [this.currentPhone.country] = this.prefixOptions;
    },
    addPhoneCouple() {
      if (
        this.currentPhone.number &&
        this.currentPhone.number !== null &&
        validatorPhoneValidator(this.currentPhone.number)
      ) {
        this.currentPhone.extension = this.currentPhone?.country?.phoneCode ? this.currentPhone?.country?.phoneCode : 0;
        this.phones.push(this.currentPhone);
        this.currentPhone = {};
        this.isValid = null;
        this.isValidPrefix = null;
      } else if (this.isRemoving) {
        this.isValid = null;
      } else {
        this.isValid = false;
      }
      this.isFirstPhoneSectionVisible = true;
      this.$emit('save-phones', this.phones);
      this.resetPrefix();
    },
    async savePhones() {
      this.phones = this.phones.filter(({ phone }) => phone !== "");
      this.phones.length > 0 ? (this.isFirstPhoneSectionVisible = false) : true;
      this.currentPhone = {};
      this.phones = this.phones.map(({ phone, label, country }) => ({
        phone,
        label,
        extension: country?.phoneCode,
      }));
      this.addPhoneCouple();
    },
    validator(item) {
      this.isValid = validatorPhoneValidator(item);
    },
    async getPrefixes() {
      const response = await this.$store.dispatch("getPrefixes");
      this.prefixOptions = response.data;
    },
    translateTranslationTable,
    removeItem(index) {
      this.phones.splice(index, 1);
      this.isRemoving = true;
      if (this.phones.length <= 0) {
        this.addPhoneCouple();
      }
    },
    getNumber(phone) {
      return phone.number ?? phone.phone;
    }
  },
};
</script>
