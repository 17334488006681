<template>
  <span>
    <div v-if="isFirstLinkSectionVisible" class="d-flex flex-wrap">
      <!-- Type -->
      <b-form-group>
        <label for="link-type" class="font-weight-bold"> {{ $t("backoffice.settings.type") }}</label>
        <v-select
          id="link-type"
          v-model="currentLink.label"
          :searchable="false"
          :placeholder="$t('form.select-placeholder')"
          :options="linkLabelsOptions"
          :clearable="false"
          style="min-width: 200px"
        />
      </b-form-group>
      <!-- Name -->
      <b-form-group class="ml-sm-1 flex-grow-1">
        <label for="link-name" class="font-weight-bold"> {{ $t("backoffice.settings.name") }}</label>
        <b-form-input
          id="link-name"
          v-model="currentLink.name"
          type="text"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
      <!-- Link -->
      <b-form-group class="ml-md-1 flex-grow-1">
        <label for="link-name" class="font-weight-bold"> {{ $t("backoffice.settings.link-label") }}</label>
        <b-form-input
          id="link-name"
          v-model="currentLink.url"
          type="url"
          :state="isValid"
          @input="validator(currentLink.url)"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
    </div>
    <div class="ml-25 d-flex justify-content-end mb-50">
      <b-button size="sm" variant="primary" @click="addLinkCouple()">
        {{$t('form.actions.add-link')}}
      </b-button>
    </div>
    <label class="font-weight-bold">
      {{ $t('contacts.list-links') }}
    </label>
    <div v-if="links.length === 0" class="d-flex flex-wrap justify-content-center bg-light px-1 pt-50 mt-50 mb-1 rounded border"><small class="text-muted pt-50 pb-1 font-weight-bold">{{$t('contacts.no-links')}}</small></div>
    <!-- All emails -->
    <div v-else>
      <div v-for="(link, index) in links" :key="index" class="d-flex flex-wrap mb-1 bg-light px-1 pt-50 mt-50 rounded border">
        <!-- Type -->
        <b-form-group>
          <label for="link-type" class="font-weight-bold"> {{ $t("backoffice.settings.type") }}</label>
          <b-form-input
            id="link-name"
            v-model="links[index].label"
            type="text"
            plaintext
            placeholder="--"
          />
        </b-form-group>
        <!-- Name -->
        <b-form-group class="ml-sm-1 flex-grow-1">
          <label for="link-name" class="font-weight-bold"> {{ $t("backoffice.settings.name") }}</label>
          <b-form-input
            id="link-name"
            v-model="links[index].name"
            type="text"
            plaintext
            placeholder="--"
          />
        </b-form-group>
        <!-- Link -->
        <b-form-group class="ml-md-1 flex-grow-1">
          <label for="link-name" class="font-weight-bold"> {{ $t("backoffice.settings.link-label") }}</label>
          <b-form-input
            id="link-name"
            v-model="links[index].url"
            type="url"
            plaintext
            placeholder="--"
          />
        </b-form-group>
        <feather-icon
          icon="Trash2Icon"
          size="16"
          class="ml-50 text-primary pointer mt-2"
          @click="removeItem(index)"
        />
      </div>
    </div>
  </span>
</template>

<script>
import vSelect from 'vue-select';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import ActiveLinksLabels from '@/@core/constants/ActiveLinksLabels';
import { validatorUrlValidator } from '@/@core/utils/validations/validators';

export default {
  name: 'Links',
  components: {
    vSelect,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    profileLinks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentLink: {
        label: 'Website',
        name: null,
        url: null,
      },
      links: [],
      isValid: null,
      isFirstLinkSectionVisible: false,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    linkLabelsOptions() {
      return ActiveLinksLabels;
    },
    isSaveEnabled() {
      return this.form.name && this.form.slug && (this.form.accessibilityAux || this.form.accessibility) && (this.form.visibilityAux || this.form.visibility);
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isDisabled() {
      return !this.form.name || !this.form.slug || !this.form.visibility || !this.form.accessibility;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  mounted() {
    this.links = this.profileLinks.map((item) => item);
    this.isFirstLinkSectionVisible = true;
  },
  methods: {
    addLinkCouple() {
      if (this.currentLink.url && this.currentLink.url !== null && validatorUrlValidator(this.currentLink.url)) {
        this.links.push(this.currentLink);
        this.currentLink = {
          label: 'Website',
          name: null,
          url: null,
        };
        this.isValid = null;
      } else if (this.isRemoving) {
        this.isValid = null;
      } else {
        this.isValid = false;
      }
      this.isFirstLinkSectionVisible = true;
      this.$emit('save-links', this.links);
    },
    validator(item) {
      this.isValid = this.isRemoving ? true : validatorUrlValidator(item);
    },
    removeItem(index) {
      this.links.splice(index, 1);
      this.isRemoving = true;
      this.addLinkCouple();
    },
  },
};
</script>
